<template>
  <v-container
    class="has-background-image fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
      dense
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
      >
        <v-col cols="12">
          <div class="text-center ma-3">
            <h1> إكمال تسجيل الحساب</h1>
          </div>
        </v-col>
        <v-card class="glassy-card">
          <v-card-text>
            <v-form
              :disabled="loading"
              @submit.prevent="login"
            >
              <h4> رقم التأكيد</h4>
              <v-text-field
                v-model="name"
                :rules="nameRules"
                label="رقم التأكيد"
                prepend-inner-icon="mdi-email"
                outlined
                required
              />

              <v-btn
                color="primary"
                type="submit"
                :loading="loading"
                x-large
                block
                dark
              >
                إنهاء التسجيل
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoginPage',
  data () {
    return {
      form: {
        name: '',
      },
      loading: false,
      errors: {},
    }
  },

  methods: {
    login () {
      const self = this
      const payload = self.form
      self.loading = true

      self.$store.dispatch('login', payload).then(() => {
        self.loading = false
        self.$router.push({ name: 'home' })
      }).catch(err => {
        self.loading = false
        self.errors = err.response.data.errors
      })
    },
  },
}
</script>
